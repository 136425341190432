/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

.side-bar-icon {
    font-size: 1.2em;
    margin-right: 10px;
    color: #5b6e88;
}

.active>.side-bar-icon {
    color: #0162e8;
}

.text-align-right {
    text-align: right;
}

.big-modal {
    max-width: 95% !important;
}

.verical-middle {
    vertical-align: middle !important;
}

.display-inline {
    display: inline !important;
}

.k-input {
    font-weight: 300 !important;
}

.font-bold {
    font-size: medium;
    font-weight: bold;
}

.search-button {
    margin-top: 26px;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.print,
.print * {
    display: none !important;
}

@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }

    .card-body {
        padding: 0 !important;
    }

    .print,
    .print * {
        display: initial !important;
    }
}

@page {
    size: auto;
}

.btn-eye {
    visibility: hidden !important;
}

.show-eye:hover {
    .btn-eye {
        visibility: visible !important;
    }
}

iframe {
    width: 100vw;
    height: 100vh;
}

.text-center {
    text-align: center !important;
}

#acadamicgraph{
  height: 300px !important;
  width: auto !important;
  display: table;
}
